<template>
  <v-container xs12 sm6 offset-sm3>
    <error-start />
    <ta-start-element :onClick="start" />
  </v-container>
</template>

<script>
import errorStart from "@/components/core/errorStart";
import TaStartElement from "@/components/landing/TaStartElement";
export default {
  name: "Start",
  components: { TaStartElement, errorStart },
  data() {
    return {};
  },

  methods: {
    start() {
      this.$router.push({ name: "intro_a" });
    },
  },
};
</script>

<style scoped>
.hoofd {
  padding-top: 15vh;
}

.logoA {
  animation-duration: 3s;
}

.logoB {
  animation-duration: 1s;
  animation-delay: 3s;
}
.logoC {
  animation-duration: 1s;
  animation-delay: 3s;
}

.verder {
  animation-delay: 3s;
}

v-img {
  margin-left: 20px;
}
</style>
