<template>
  <div>
    <ta-statement :stat-text="statTextMulti[1]" :formal="formal" />
    <ta-val-input
      v-model="firstNameClient"
      :label="statTextMulti[1].label"
      rules="required|max:50"
    />
    <ta-statement :stat-text="statTextMulti[2]" :formal="formal" />
    <ta-val-input
      v-model="lastNameClient"
      :label="statTextMulti[2].label"
      rules="required|max:150"
    />
    <ta-statement :stat-text="statTextMulti[3]" :formal="formal" />
    <ta-val-input
      v-model="emailClient"
      :label="statTextMulti[3].label"
      rules="required|email"
    />
    <ta-statement :stat-text="statTextMulti[4]" :formal="formal" />
    <ta-val-input
      v-model="telephoneClient"
      :label="statTextMulti[4].label"
      rules="max:20"
    />
    <ta-statement :stat-text="statTextMulti[5]" :formal="formal" />
    <ta-val-input
      v-model="companyNameClient"
      :label="statTextMulti[5].label"
      rules="max:100"
    />
    <ta-statement :stat-text="statTextMulti[6]" :formal="formal" />
    <ta-val-input
      v-model="contactClient"
      :label="statTextMulti[6].label"
      rules="max:100"
    />
    <ta-statement :stat-text="statTextMulti[7]" :formal="formal" />
  </div>
</template>

<script>
export default {
  name: "taClientInfo",
  components: {},
  props: ["statTextMulti", "formal"],
  data() {
    return {};
  },
  computed: {
    firstNameClient: {
      get() {
        return this.$store.state.quickAll.firstNameClient;
      },
      set(value) {
        this.$store.commit("quickAll/update_firstNameClient", value);
      },
    },
    lastNameClient: {
      get() {
        return this.$store.state.quickAll.lastNameClient;
      },
      set(value) {
        this.$store.commit("quickAll/update_lastNameClient", value);
      },
    },
    emailClient: {
      get() {
        return this.$store.state.quickAll.emailClient;
      },
      set(value) {
        this.$store.commit("quickAll/update_emailClient", value);
      },
    },
    telephoneClient: {
      get() {
        return this.$store.state.quickAll.telephoneClient;
      },
      set(value) {
        this.$store.commit("quickAll/update_telephoneClient", value);
      },
    },
    contactClient: {
      get() {
        return this.$store.state.quickAll.contactClient;
      },
      set(value) {
        this.$store.commit("quickAll/update_contactClient", value);
      },
    },
    companyNameClient: {
      get() {
        return this.$store.state.quickAll.companyNameClient;
      },
      set(value) {
        this.$store.commit("quickAll/update_companyNameClient", value);
      },
    },
  },
  methods: {},
};
</script>

<style scoped></style>
